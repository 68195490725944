<template>
  <base-page-layout title="Campanhas">
    <mf-card-container
      v-model="search"
      placeholder="Buscar"
      title=" Listagem de Campanhas"
      description="
        Listagem de todas as campanhas cadastradas nesta conta, filtradas por seu tipo.
      "
      use-search
    >
      <template #headers>
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            <v-menu v-model="menu" left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-badge class="mr-4" color="primary" :value="filtersQuantity" :content="filtersQuantity" overlap>
                  <v-btn icon v-on="on">
                    <v-icon x-large>filter_list</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-card width="285px" class="pt-4 pl-4">
                <mf-toggle
                  v-model="campaignsFilters.campaigns"
                  class="mx-2"
                  label="Campanhas"
                  color="#334A58"
                  :value="campaignsFilters.campaigns"
                  @change="campaignsFilters.campaigns ? refetchCampaigns('campaigns') : null"
                />
                <mf-toggle
                  v-model="campaignsFilters.omni_campaigns"
                  class="mx-2"
                  label="Super Campanhas"
                  color="#334A58"
                  value
                  @change="refetchCampaigns('omni_campaigns')"
                />
                <mf-toggle
                  v-model="campaignsFilters.offer_schedule"
                  class="mx-2"
                  label="Gestão de Ofertas"
                  color="#334A58"
                  value
                  @change="refetchCampaigns('offer_schedule')"
                />
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <v-row no-gutters align="center">
        <v-col cols="12">
          <v-data-table
            :search="search"
            :server-items-length="maxRecords"
            :headers="headers"
            :items="campaigns"
            :loading="$apollo.queries.accountCampaigns.loading"
            :options.sync="options"
          >
            <template v-slot:[`item.title`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.title }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.mode`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon
                    class="mr-2"
                    x-small
                    :color="tagColors(!item.title && !item.initial_date ? 'GESTÃO OFERTAS' : !item.mode ? 'SUPER CAMPANHA' : item.mode)"
                  >
                    mdi-brightness-1
                  </v-icon>
                  {{ !item.title && !item.initial_date ? 'GESTÃO OFERTAS' : !item.mode ? 'SUPER CAMPANHA' : item.mode }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.initial_date`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center> {{ formatDateWithoutHours(item.initial_date || item.start_date) }} </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.final_date`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ formatDateWithoutHours(item.final_date || item.end_date) }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ formatDate(item.created_at) }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.scheduled`]="{ item }">
              <v-layout v-if="(item.title && !item.mode) || (item.initial_date && !item.mode)" justify-center align-center class="m-0 p-0">
                <v-icon class="mr-2" x-small :color="tagColors(item.active ? 'ACTIVE' : 'INACTIVE')">
                  mdi-brightness-1
                </v-icon>
                {{ item.active ? 'Sim' : 'Não' }}
              </v-layout>
              <v-layout v-else-if="item.title || item.initial_date" justify-center align-center class="m-0 p-0">
                <v-icon class="mr-2" x-small :color="tagColors(item.scheduled ? 'ACTIVE' : 'INACTIVE')">
                  mdi-brightness-1
                </v-icon>
                {{ item.scheduled ? 'Sim' : 'Não' }}
              </v-layout>
            </template>
            <template v-slot:[`item.metrics`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-menu :close-on-content-click="false">
                    <template #activator="{ on }">
                      <v-btn class="ml-2" icon v-on="on">
                        <mf-icon>more_vert</mf-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openCampaign(item._id)">
                        <v-list-item-title>Ver detalhes </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="(item.title && !item.mode) || (item.initial_date && !item.mode)" @click="confirmScheduled(item)">
                        <v-list-item-title>{{ item.active ? 'Desativar envio' : 'Ativar envio' }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else-if="item.title || item.initial_date" @click="confirmScheduled(item)">
                        <v-list-item-title>{{ item.scheduled ? 'Desativar envio' : 'Ativar envio' }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-layout>
              </v-flex>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </mf-card-container>

    <campaign-details
      v-if="campaignId && accountId"
      :dialog="campaignDetails"
      :campaign-id="campaignId"
      :account-id="accountId"
      @close="closeCampaignDetails"
    />

    <!-- confirmation modal -->
    <v-dialog v-model="scheduledStatusWarning" max-width="500" persistent transition="dialog-bottom-transition">
      <v-card class="py-4">
        <v-card-title><v-icon class="mx-auto" color="error" x-large>mdi-alert-circle</v-icon></v-card-title>
        <v-card-text v-if="selectedCampaign && selectedCampaign.title" class="text-center"
          >Tem certeza que deseja alterar o status do envio dessa campanha? {{ selectedCampaign.title }}</v-card-text
        >
        <v-card-actions class="justify-center pb-4">
          <v-btn
            color="red darken-1"
            class="mr-2"
            outlined
            @click="changeScheduledStatus(selectedCampaign._id, selectedCampaign.scheduled || selectedCampaign.active)"
            ><v-icon class="mr-1">mdi-alert</v-icon>Continuar</v-btn
          >
          <v-btn color="green darken-1" outlined @click="scheduledStatusWarning = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-page-layout>
</template>
<script>
import { QUERY_CAMPAIGNS, MUTATION_CHANGE_SCHEDULED_STATUS } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    CampaignDetails: () => import('@/modules/accounts/components/campaigns/CampaignDetails')
  },
  data: () => ({
    accountId: '',
    search: '',
    campaignDetails: false,
    campaignId: '',
    campaigns: [],
    scheduledStatusWarning: false,
    selectedCampaign: {},
    filters: ['campaigns'],
    filtersQuantity: 1,
    campaignsFilters: {
      campaigns: true,
      omni_campaigns: false,
      offer_schedule: false
    },
    menu: false,
    maxRecords: 0,
    options: {}
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Título',
          value: 'title',
          width: '15%',
          align: 'center'
        },
        {
          text: 'Descrição',
          value: 'description',
          width: '20%',
          align: 'center'
        },
        {
          text: 'Modo',
          value: 'mode',
          width: '15%',
          align: 'center'
        },
        {
          text: 'Data Inicial',
          value: 'initial_date',
          width: '10%',
          align: 'center'
        },
        {
          text: 'Data Final',
          value: 'final_date',
          width: '10%',
          align: 'center'
        },
        {
          text: 'Criado em',
          value: 'created_at',
          width: '15%',
          align: 'center'
        },
        {
          text: 'Envio Ativo',
          value: 'scheduled',
          width: '10%',
          align: 'center'
        },
        {
          text: '',
          value: 'metrics',
          width: '5%',
          align: 'center'
        }
      ]
    }
  },
  apollo: {
    accountCampaigns: {
      query: QUERY_CAMPAIGNS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          account_id: this.accountId,
          params: {
            filter: Object.keys(this.campaignsFilters).filter(key => this.campaignsFilters[key]),
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.search || null
          }
        }
      },
      update({ accountCampaigns }) {
        this.campaigns = accountCampaigns.campaigns
        this.maxRecords = accountCampaigns.count
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async changeScheduledStatus(id, value) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CHANGE_SCHEDULED_STATUS,
          variables: {
            account_id: this.accountId,
            campaign_id: id,
            value: !value
          }
        })
        this.$snackbar({ message: 'Agendamento atualizado' })
        this.selectedCampaign = {}
        this.scheduledStatusWarning = false
        this.$apollo.queries.accountCampaigns.refetch()
      } catch (err) {
        this.$snackbar({ message: 'Falha ao alterar status da campanha' })
      }
    },
    formatDate(date) {
      return this.$moment(date)
        .add(3, 'h')
        .format('DD/MM/YYYY - HH:mm:ss')
    },
    formatDateWithoutHours(date) {
      try {
        const formDate = new Date(date / 1).toISOString()
        return this.$moment(formDate).format('DD/MM/YYYY')
      } catch (error) {
        return this.$moment(date)
          .add(3, 'h')
          .format('DD/MM/YYYY')
      }
    },
    confirmScheduled(item) {
      this.selectedCampaign = item
      this.scheduledStatusWarning = true
    },
    openCampaign(id) {
      this.campaignDetails = true
      this.campaignId = id
    },
    closeCampaignDetails() {
      this.campaignDetails = false
      this.campaignId = ''
    },
    refetchCampaigns(filter) {
      this.options.page = 1
      Object.keys(this.campaignsFilters).forEach(key => {
        if (key !== filter) this.campaignsFilters[key] = false
        else this.campaignsFilters[key] = true
      })
      this.$apollo.queries.accountCampaigns.refetch()
    },
    tagColors(value) {
      const tagObject = {
        SMS: '#20c997',
        EMAIL: '#20c997',
        WHATSAPP: '#20c997',
        'SUPER CAMPANHA': '#FFF176',
        'GESTÃO OFERTAS': '#339af0',
        ACTIVE: '#20c997',
        INACTIVE: '#adb5bd'
      }

      return tagObject[value] || '#adb5bd'
    }
  }
}
</script>
<style lang="scss">
.toggle-table {
  height: 56px !important;
}
</style>
